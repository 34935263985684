import { useState, useEffect } from 'react';
import { Amplify, Storage } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { Link, Authenticator } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';
import React from 'react';
Amplify.configure(awsExports);

const listFiles = async () => {
  try {
    const fileResponse = await Storage.list('', { level: 'protected' });
    //console.log(processStorageList(fileResponse));
    return fileResponse;
  } catch (error) {
    console.error('error happened', error);
  }
}

const processStorageList = (results) => {
  const filesystem = {}
  const add = (source, target, item) => {
    const elements = source.split("/");
    const element = elements.shift();
    if (!element) return // blank
    target[element] = target[element] || { __data: item }// element;
    if (elements.length) {
      target[element] = typeof target[element] === "object" ? target[element] : {};
      add(elements.join("/"), target[element], item);
    }
  };
  results.forEach(item => add(item.key, filesystem, item));
  return filesystem
}

const getFileUrl = async (key) => {
  try {
    const fileResponse = await Storage.get(key, { level: 'protected', download: 'true' });
    return fileResponse;
  } catch (error) {
    console.error('error happened', error);
  }
}

const generateDownloadLinks = async (filesystem) => {
  try {
    //useState mandates updating without mutation
    const urlfiles = { ...filesystem };
    const files = Object.keys(urlfiles);
    files.forEach(async (file) => {
      urlfiles[file].__data.signedURL = await getFileUrl(urlfiles[file].__data.key);
    });
    return urlfiles;
  } catch (error) {
    console.error('error happened', error);
  }
}




export default function App() {


  const [S3Files, setS3Files] = useState([]);
  const [reload, setReload] = useState(true)

  useEffect(() => {
    const refreshList = async () => {
      if (reload) {
        const files = await listFiles();
        const file_list = processStorageList(files);
        const file_list_with_urls = await generateDownloadLinks(file_list);
        setS3Files(file_list_with_urls);
      }
    }
    refreshList();
  }, [reload]);


  function FileTableBody() {
    const fileItems = Object.keys(S3Files).map((file) =>
      <FileItem key={file} fileprop={S3Files[file].__data} />
    );
    return (
      <tbody>
        {fileItems}
      </tbody>
    )
  }

  function FileItem({ fileprop }) {
    return (
      <tr>
        <td><Link href={fileprop.signedURL} isExternal={true}>{fileprop.key}</Link></td>
        <td>{fileprop.lastModified.toISOString()}</td>
        <td>{fileprop.size}</td>
      </tr>
    );
  }


  return (
    <Authenticator>
      {({ signOut, user }) => (
        <main>
          <div>
            <h1>Hello {user.attributes.email}</h1>
            <p>
            The current front-end PoC has a bug related to the usage of async fetching in the useEffect().
            </p>
            <p>
            For now, it is needed to press the Reload button once on first render to activate the downloadable secure URLs for the files.
            </p>
            <button onClick={signOut}>Sign out</button>
            <button onClick={() => setReload(!reload)}>Reload</button>            
          </div>
          <div>
            <h2>File List</h2>
            <table>
              <thead>
                <tr>
                  <th key={1}>Name</th>
                  <th key={2}>Modified</th>
                  <th key={3}>Size</th>
                </tr>
              </thead>
              <FileTableBody />
            </table>
          </div>
        </main>
      )}
    </Authenticator>
  )
}
