/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:54d1c97c-cdf3-4fc0-96d2-7a6f5a2ae90f",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_qCtzp7Jek",
    "aws_user_pools_web_client_id": "5dvr78hi3f497e8u34rf1vlt8d",
    "oauth": {
        "domain": "auth.oreos.r3coms.net.auth.eu-central-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "r3oreosimagesapp-storage-d1af67c3154721-staging",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://2xoomb4cgvhfnkz57n5gvcidza.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-wwm3mjolc5cj7jtcbeevb62rdi"
};


export default awsmobile;
